.waypointsBox {
  justify-items: center;
  align-content: center;
  margin: 10px;
  padding: 10px;
  color: white;
}

.waypointInput {
  border: thin;
  border-color: black;
  display: block;
  width: 100%;
}
