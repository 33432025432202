.addPlacesButton {
  font-size: 16px;
  color: var(--color-primary);
  padding-bottom: 6px;
  font-weight: 500;
  width: 100%;
}
.addPlaceIconSVG {
  fill: var(--color-primary);
}
.addPlaceIconText {
  color: var(--color-grey);
  font-size: 14px;
}

/* NOT CURRENTLY WORKING DUE TO DEFAULT BUTTON IMPLEMENTATION */
.addPlaceIconButton {
  border-radius: 6px;
}
.addPlaceIconButton:hover {
  background: grey;
}

.AddPlacesModal--string-input {
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  width: 80%;
}

.AddPlacesModal--submit-button {
  padding: 8px 16px;
  font-size: 16px;
  background-color: #659df6;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.AddPlacesModal--submit-button:hover {
  background-color: #2f79e1;
}

.AddPlacesModal--submit-button:disabled {
  pointer-events: none;
  opacity: 0.3;
}

.AddPlacesModal--button-container {
  width: 100%;
  text-align: right;
  padding: 10px;
}

.AddPlacesModal--loading-screen {
  min-height: 350px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5; /* Choose a background color that matches your app's theme */
}

.PlacesModal--loading-message {
  font-size: 24px;
  color: #333; /* Choose a color that matches your app's theme */
  margin-bottom: 20px;
}

.AddPlacesModal--spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

.AddPlacesModal--checkbox-input {
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.AddPlacesModal--checkbox-input:checked {
  background-color: #659df6;
}

.AddPlacesModal--checkbox-container {
  display: flex;
  align-items: center;
  padding: 10px;
}
