.PlaceDetailsView {
  overflow-y: scroll;
  height: 100%;
}

.PlaceDetailsView--heading {
  color: #fff;
  position: absolute;
  top: 250px;
  margin-left: 15px;
  line-height: 24px;
  font-family: var(--font-secondary);
}

.PlaceDetailsView--box {
  background-color: white;
  top: 425px;
  width: 440px;
  max-width: 100vw;
  height: 30px;
  position: absolute;
  border-radius: 15px;
}

.PlaceDetailsView--contents {
  margin-top: 13px;
  padding: 20px;
  font-family: var(--font-secondary);
}

.PlaceDetailsView--subtitle {
  position: absolute;
  margin-left: 15px;
  top: 275px;
  font-weight: 600;
  font-size: 12px;
  font-family: var(--font-secondary);
}

.PlaceDetailsView--flexbox {
  display: flex;
}

.PlaceDetailsView--star {
  cursor: pointer;
  margin-left: 62%;
}

.FavoriteStar {
  fill: var(--color-secondary);
}

.PlaceDetailsView--button {
  width: 100%;
  position: relative;
  border-radius: 4px;
  box-sizing: border-box;
  font-family:
    BlinkMacSystemFont,
    Roboto,
    Helvetica Neue,
    sans-serif;
  background-color: #ffffff;
  border: 1.5px solid var(--color-primary) !important;
  color: var(--color-primary);
  display: block;
  font-weight: 600 !important;
  padding: 5px 9px;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  margin-top: 11px;
}

.PlaceDetailsView--button:hover {
  background-color: rgba(101, 157, 246, 0.2);
}

.PlaceDetailsView--backButton {
  position: absolute;
  font-size: 16px;
  color: white;
  padding-bottom: 6px;
  font-weight: 500;
  margin-top: 5px;
  margin-left: 5px;
  cursor: pointer;
  z-index: 4;
}

.PlaceDetailsView--img-card {
  position: relative;
}
