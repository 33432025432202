.profileCardWrapper {
  height: 100%;
  width: fit-content;
  display: flex;
  margin: 2.5%;
  align-items: center;
  margin-bottom: 4%;
  min-width: 227px;
}

.profileCardDisplayName {
  color: black;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
}

.profile-card-container {
  display: flex;
  justify-content: space-between;
}

.follow-button {
  position: relative;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  background-color: #ffffff;
  border: 1px solid #dbdbdb;
  color: #262626;
  display: block;
  font-weight: 600;
  padding: 5px 9px;
  text-align: center;
  text-transform: inherit;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 18px;
}

.follow-button-container {
  display: flex;
  align-items: center;
  padding-right: 10%;
}

.profile-card-container:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
