.flex-container {
  display: flex;
}

.MapContainer {
  height: calc(100vh - 61px);
  width: calc(100vw - 440px);
}

.MapContainer--isMobile {
  width: calc(100vw);
}

.TripViewColumn {
  height: calc(100vh - 61px);
  width: 440px;
  max-width: 100vw;
  overflow-y: auto;
}

.TripViewColumn--isMobile {
  width: 100vw;
}

.signed-out-column {
  height: calc(100vh - 61px);
  text-align: center;
}

.signed-out-text {
  font-family: 'Roboto', sans-serif;
  color: #444444;
  font-size: 14pt;
  font-weight: 500;
  position: relative;
  top: 33%;
}

.login-button {
  position: relative;
  top: 36%;
  margin: 0 auto;
  transition: all 0.2s;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: var(--font-primary);
  background-color: #ffffff;
  border: 1px solid var(--color-grey);
  color: var(--color-blackish);
  display: block;
  font-weight: 600;
  padding: 5px 9px;
  text-align: center;
  text-transform: inherit;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 18px;
  width: 125px;
}

@media all and (max-width: 30em) {
  a.login-button {
    display: block;
    margin: 0.4em auto;
  }
}

.loading-screen {
  display: flex;
  flex-direction: column;
  padding-top: 20%;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: #f5f5f5; /* Choose a background color that matches your app's theme */
}

.loading-message {
  font-size: 24px;
  color: #333; /* Choose a color that matches your app's theme */
  margin-bottom: 5px;
}

.loading-subtext {
  font-size: 14px;
  color: #333; /* Choose a color that matches your app's theme */
  opacity: 0.7;
  margin-bottom: 20px;
}

.spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.flex-container {
  display: flex;
}

.left-panel {
  width: 440px; /* or adjust as needed */
}

.right-panel {
  flex-grow: 1; /* or adjust as needed */
}

.SignUpToolTip--container {
  position: absolute;
  bottom: 25px;
  right: 25px;
  z-index: 1;
  width: 360px;
}

.SignUpToolTip--container--isMobile {
  position: absolute;
  bottom: 100px;
  right: 25px;
  z-index: 1;
  width: 360px;
}
