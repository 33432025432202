.navHeader {
  box-shadow: 0px 5px 10px var(--color-grey);
  width: 100vw;
}

.navHeader--isMobile {
  border-bottom-style: solid;
  border-bottom-width: 0.1px;
  border-bottom-color: var(--color-grey);
  width: 100vw;
}

.accountDropdown {
  position: absolute;
  padding: 12px 8px;
  text-align: center;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  margin-left: -60px;
  width: 200px;
}

.navbar-login-button {
  position: relative;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: var(--font-primary);
  background-color: #ffffff;
  border: 1px solid var(--color-grey);
  color: var(--color-blackish);
  display: block;
  font-weight: 600;
  padding: 5px 9px;
  text-align: center;
  text-transform: inherit;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 18px;
  width: 125px;
}

@media all and (max-width: 30em) {
  a.navbar-login-button {
    display: block;
    margin: 0.4em auto;
  }
}
