.RouteCard {
  background-color: white;
  width: 100%;
  height: 125px;
  padding: 15px;
  font-weight: bold;
}

.RouteCard-active {
  background-color: rgba(0, 0, 0, 0.075);
}

.RouteCard:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.detailsButton {
  color: blue;
  border: none;
  background: none;
  font-weight: 500;
  font-size: large;
  margin-top: 6px;
  padding: 0px;
}
.detailsButton:focus {
  outline: none;
}

.detailsArrowDown {
  writing-mode: vertical-rl;
}
