.PhotoCarousel--container {
  margin-top: 10px;
}

.PhotoCarousel--photo--container {
  margin-top: 5px;
  height: 100px;
  display: flex;
  justify-content: center;
}

.PhotoCarousel--img {
  width: 100px;
  border-radius: 4px;
  cursor: pointer;
}

.PhotoCarousel--arrow {
  cursor: pointer;
  height: 100%;
}

.PhotoCarousel--arrow:hover {
  fill: var(--color-primary);
}

.PhotoCarousel--title {
  padding-left: 20px;
  font-family: var(--font-primary);
  font-weight: 700;
}

.PhotoCarousel--line {
  display: block;
  height: 1px;
  width: 100%;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0 auto;
  margin-bottom: 10px;
  padding: 0;
}
