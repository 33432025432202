.search-profile-card-wrapper {
  height: 100%;
  display: flex;
  margin: 2.5%;
  align-items: center;
  margin-bottom: 4%;
  min-width: 227px;
}

.profileCardDisplayName {
  color: black;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
}

.search-profile-card-wrapper:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
