.searchDropdownCategory {
  background: var(--color-secondary);
  font-family: var(--font-primary);
  font-size: 15px;
  font-weight: 600;
  color: white;
  width: 100%;
}

.searchDropdown {
  position: absolute;
  width: 360px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.075);
  text-align: center;
  z-index: 1000;
  background-color: white;
}

.searchDropdown--noSearchTerm {
  display: none;
}

.profile-card-container {
  padding: 1%;
}

.searchDropdown--content {
  padding: 12px 8px;
}
