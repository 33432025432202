.defaultButton {
  border: none;
  background-color: transparent;
  outline: none;
  padding: 0;
}
.defaultButton:focus {
  border: none;
  background-color: transparent;
  outline: none;
}
