.LoginModal--signup--container {
  margin: 40px auto;
  text-align: center;
}

.LoginModal--input {
  width: 258px;
  margin-bottom: 10px;
  font-size: 16px;
}

.LoginModal--login--buttons {
  width: 258px;
  height: 30px;
  margin-top: 2.5%;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: var(--font-secondary);
  background-color: var(--color-primary);
  color: white;
  font-weight: 600;
  padding: 5px 9px;
  text-align: center;
  text-transform: inherit;
  text-overflow: ellipsis;
  font-size: 12px;
  line-height: 18px;
  border-width: 0px;
}

.LoginModal--login--buttons:disabled,
button[disabled] {
  background-color: var(--color-primary);
  opacity: 0.4;
}

.LoginModal--forgot-password-link {
  color: dodgerblue;
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
}

.google-auth-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 258px;
  height: 40px; /* Increase the height to make the button larger */
  margin: 20px auto; /* Center the button horizontally */
  padding: 0 15px; /* Add some padding at the sides */
  border-radius: 4px;
  font-family: var(--font-secondary);
  background-color: var(--color-primary);
  color: white;
  font-weight: 600;
  text-align: center;
  text-transform: inherit;
  text-overflow: ellipsis;
  font-size: 16px; /* Increase the font size to make the text larger */
  line-height: 24px;
  border-width: 0px;
  transition: background-color 0.3s ease;
}

.google-auth-button:disabled,
button[disabled] {
  background-color: var(--color-primary);
  opacity: 0.4;
}

.google-auth-button .google-logo {
  width: 24px; /* Increase the logo size */
  height: 24px;
  margin-right: 15px;
}

.LoginModal--prompt--container {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.LoginModal--prompt {
  padding-right: 5px;
  font-size: 14px;
  font-family: var(--font-secondary);
}

.LoginModal--prompt--button {
  cursor: pointer;
  color: dodgerblue;
  font-size: 14px;
  font-family: var(--font-secondary);
}

.LoginModal--image--container {
  position: relative;
  width: 100vw;
  height: 750px;
  overflow: hidden;
}

.LoginModal--absolute--container {
  position: absolute;
  z-index: 1;
  border-radius: 4px;
  top: 50px;
  background-color: white;
  width: 350px;
}

.LoginModal--form--flex--box {
  width: 350px;
  background-color: white;
  border-radius: 4px;
  padding: 40px;
}

.LoginModal--header--container {
  color: #8e8e8e;
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
  margin: 0 40px 10px;
  text-align: center;
  margin-bottom: 25px;
}

.LoginModal--exit {
  right: 15px;
  cursor: pointer;
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.LoginModal--exit:hover {
  color: #3078e1;
  opacity: 1;
}
