.placeConnectionLine {
  background: var(--color-secondary);
  border-radius: 0 0 2px 2px;
  border: none;
  height: 140px;
  
  
  width: 4px;
  padding-top: 0px;
  position: relative;
  z-index: 10;
}
.placeConnectionLine:hover {
  background: var(--color-primary);
}
