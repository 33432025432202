.Reviews--container {
  margin-top: 10px;
}

.Review--container {
  margin: 10px;
  align-items: center;
  white-space: nowrap;
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Reviews--author--img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.Reviews--title {
  padding-left: 20px;
  font-family: var(--font-primary);
  font-weight: 700;
}

.Reviews--line {
  display: block;
  height: 1px;
  width: 100%;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0 auto;
  margin-bottom: 10px;
  padding: 0;
}

.Reviews--text--container {
  white-space: nowrap;
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.Reviews--modal--button {
  font-family: var(--font-primary);
  color: var(--color-primary);
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  cursor: pointer;
  font-weight: 500;
}

.Reviews--avg--rating {
  margin: 10px;
  font-size: 60px;
}

.Reviews--summary--container {
  display: flex;
  padding: 20px;
  align-items: center;
}

.Reviews--star--rating {
  font-family: var(--font-primary);
  color: #fbbc04;
}
