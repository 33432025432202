.DateRangePicker_1 {
    width: 100%;
    height: 48px;
  }
  
  .DateRangePickerInput_1 {
    width: 100%;
    height: 48px;
    border-radius: 5px;
  }

.DateRangePickerInput_1 {
  width: 100%;
  height: 48px;
  border-radius: 5px;
}

.GenerateTripForm-trip-button {
  width: 150px;
  height: 48px;
  position: relative;
  border-radius: 4px;
  box-sizing: border-box;
  font-family:
    BlinkMacSystemFont,
    Roboto,
    Helvetica Neue,
    sans-serif;
  background-color: #ffffff;
  border: 1.5px solid var(--color-primary) !important;
  color: var(--color-primary) !important;
  display: inline-block;
  font-weight: 600 !important;
  padding: 5px 9px;
  text-align: center;
  text-transform: inherit;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 18px;
  box-shadow: 0 8px 20px var(--color-primary);
}

.GenerateTripForm-trip-button:hover {
  color: var(--color-blackish) !important;
  box-shadow: 0 8px 20px var(--color-blackish);
  /* border: 1.5px solid var(--color-blackish) !important; */
}

.GenerateTripForm-place-input {
  width: 377px;
  height: 48px;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
}

.GenerateTripForm-additionalNotes-input {
  width: 377px;
  height: 54px;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
}

.GenerateTripForm-autocomplete-dropdown-container {
  position: absolute;
  z-index: 2;
  width: 377px;
  text-align: left;
  margin: 0 auto;
}

.GenerateTripForm-search-container {
  width: 441px;
  padding: 32px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 16px 40px rgba(0, 0, 0, 0.18);
}

.GenerateTripForm-subheading {
  width: 100%;
  text-align: left;
  padding-top: 7px;
}

.GenerateTripForm-subheading-dates {
  width: 100%;
  text-align: left;
  padding-top: 7px;
  display: flex;
}

.GenerateTripForm-heading {
  font-size: 32px;
  font-weight: 700;
  line-height: 36px;
  font-family:
    BlinkMacSystemFont,
    Roboto,
    Helvetica Neue,
    sans-serif;
  color: var(--color-blackish) !important;
}

.MobileGenerateTripForm-place-input {
  width: 325px;
  height: 48px;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
}

.MobileGenerateTripForm-additionalNotes-input {
  width: 325px;
  height: 54px;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
}

.MobileGenerateTripForm-autocomplete-dropdown-container {
  position: absolute;
  z-index: 2;
  width: 325px;
  text-align: left;
  margin: 0 auto;
}

.MobileGenerateTripForm-search-container {
  width: 100%;
  padding: 32px;
  border-radius: 5px;
  background-color: white;
}
