.searchTripCardWrapper {
  height: 100%;
  display: flex;
  margin: 2.5%;
  align-items: center;
  margin-bottom: 4%;
  min-width: 227px;
  padding: 4px;
}
.searchTripCardWrapper:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.tripCardName {
  color: black;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  max-width: 255px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.searchTripCardImage {
  border-radius: 6px;
}

.searchTripCardSubtitle {
  color: grey;
  text-decoration: none;
  text-align: left;
  cursor: pointer;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 400;
  display: block;
  flex-basis: 100%;
  width: 100%;
}
