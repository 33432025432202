.PlaceRouteConnection {
  display: flex;
}

.PlaceRouteLine--Line {
  background-image: linear-gradient(black 33%, rgba(255, 255, 255, 0) 0%);
  background-position: left;
  background-size: 2px 5px;
  background-repeat: repeat-y;
  height: 16px;
  margin-left: 16px;
  display: block;
  letter-spacing: 0.2px;
}
.PlaceRouteLine--TransportationIcon {
  fill: var(--color-grey);
  margin: 4px 0;
}
.PlaceRouteConnection--routeInfo {
  flex-grow: 1;
  margin: 26px 0 22px 20px;
  font-family: var(--font-primary);
  font-size: 15px;
  color: var(--color-blackish);
}
.PlaceRouteConnection--DirectionsIconWrapper {
  align-self: center;
  border: 1px var(--color-secondary) solid;
  border-radius: 50%;
  margin-right: 26px;
  margin: 18px 26px 16px 0px;
}
.PlaceRouteConnection--DirectionsSVG {
  fill: var(--color-primary);
  padding: 4px;
}

.PlaceRouteConnection--directionsButton {
  border-radius: 12px;
}

.PlaceRouteConnection--routeDots {
  width: 50px;
  height: 35px;
  background-image: radial-gradient(circle, black 2px, transparent 3px);
  background-size: 100% 33.33%;
  margin: 0 auto;
}

.PlaceRouteConnection--routeDots:hover {
  background-image: radial-gradient(
    circle,
    var(--color-primary) 2px,
    transparent 3px
  );
}
