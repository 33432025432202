.PlacesSearchBox {
  width: 100%;
}

.PlacesSearchBox::-webkit-search-cancel-button {
  display: none;
}

.PlacesSearchBox-clearButton {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  position: absolute;
  top: 1px;
  right: 0px;
  padding-right: 8px;
  font-weight: 900;
  font-size: 24px;
  color: grey;
}

.FloatingPlacesSearchBox {
  width: 400px;
  max-width: calc(100vw - 20px);
  margin-top: 10px;
  margin-left: 10px;
}

.FloatingPlacesSearchBox-clearButton {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  position: absolute;
  top: 11px;
  right: 0px;
  padding-right: 8px;
  font-weight: 900;
  font-size: 24px;
  color: grey;
}

.FloatingPlacesSearchBox--backButton {
  width: 125px;
  height: 25px;
  border-radius: 2px;
  background-color: white;
  font: inherit;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  color: var(--color-primary);
  padding: 4px;
  font-weight: bold;
}
