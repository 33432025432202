.Login--signup--container {
  margin: 40px auto;
  text-align: center;
}

.Login--input {
  width: 258px;
  margin-bottom: 10px;
  font-size: 16px;
}

.Login--login--buttons {
  width: 258px;
  height: 30px;
  margin-top: 2.5%;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: var(--font-secondary);
  background-color: var(--color-primary);
  color: white;
  font-weight: 600;
  padding: 5px 9px;
  text-align: center;
  text-transform: inherit;
  text-overflow: ellipsis;
  font-size: 12px;
  line-height: 18px;
  border-width: 0px;
}

.Login--login--buttons:disabled,
button[disabled] {
  background-color: var(--color-primary);
  opacity: 0.4;
}

.Login--prompt--container {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Login--prompt {
  padding-right: 5px;
  font-size: 14px;
  font-family: var(--font-secondary);
}

.Login--prompt--button {
  cursor: pointer;
  color: dodgerblue;
  font-size: 14px;
  font-family: var(--font-secondary);
}

.Login--image--container {
  position: relative;
  width: 100vw;
  height: 750px;
  overflow: hidden;
}

.Login--absolute--container {
  position: absolute;
  z-index: 1;
  border-radius: 4px;
  top: 50px;
  background-color: white;
  width: 350px;
}

.Login--form--flex--box {
  position: absolute;
  top: 20vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Login--header--container {
  color: #8e8e8e;
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
  margin: 0 40px 10px;
  text-align: center;
  margin-bottom: 25px;
}
