.placeCell {
  background-color: white;
  font-weight: bold;
  padding: 10px;
  border-radius: 12px;
}
/* Keeping two separate hover blocks is obviously not ideal,
but this is needed for when the corresponding marker is hovered */
.placeCell-hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.placeCell:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.PlaceCell--row {
  display: flex;
}

.PlaceCell--time {
  letter-spacing: 0.3px;
  color: var(--color-grey);
  font-size: 16px;
  font-weight: 500;
  padding: 16px 0px 4px 0px;
  margin-left: -4px;
}
.creatorName {
  display: inline-block;
  width: 125px;
  height: 25px;
  background: white;
  text-align: center;
  border: 1px solid lightblue;
  border-radius: 5px;
  color: lightblue;
}
.navLink {
  position: relative;
  bottom: 5%;
  left: 75%;
}
.editButtonContainer {
  position: relative;
  left: 100%;
  bottom: 0%;
  margin-top: 40px;
}
.editSVG {
  fill: var(--color-grey);
}
.editSVG:hover {
  fill: var(--color-primary);
}

.searchTripCardSubtitle {
  color: grey;
  text-decoration: none;
  text-align: left;
  cursor: pointer;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 400;
  display: block;
  flex-basis: 100%;
  width: 100%;
}

.searchTripCardSubtitle:hover {
  text-decoration: underline;
}

.PlaceCell--description {
  color: rgb(32, 33, 36);
  font-size: 14px;
  font-weight: 400;
  height: auto;
  letter-spacing: normal;
  line-height: 20px;
  margin-right: 4px;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 5px;
}
