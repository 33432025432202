.Aihero-container {
  width: 100%;
  padding-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Aitrip-form-container {
  position: absolute;
  z-index: 1;
  border-radius: 4px;
  margin-top: 10vh;
}

.MobileHero-form-container {
  z-index: 1;
  border-radius: 4px;
}

.Aiimage-container {
  position: relative;
  width: 100vw;
  height: 700px;
  overflow: hidden;
}

.Aicontent-container {
  margin-left: 4vw;
  margin-right: 4vw;
}

.Aitrip-heading {
  font-size: 32px;
  font-weight: 700;
  line-height: 36px;
  font-family:
    BlinkMacSystemFont,
    Roboto,
    Helvetica Neue,
    sans-serif;
}

.Aitr-font {
  font-family:
    BlinkMacSystemFont,
    Roboto,
    Helvetica Neue,
    sans-serif;
}
