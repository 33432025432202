.marker {
  width: 25px;
  height: 25px;
  border-radius: 50% 50% 50% 0;
  background: #00cae9;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  background-color: var(--color-primary);
}
/* Keeping two separate hover blocks is obviously not ideal,
but this is needed for when the corresponding place cell is hovered */
.marker-hover {
  z-index: 1;
  width: 35px;
  height: 35px;
  margin: -32px 0 0 -25px;
}
.marker:hover {
  z-index: 1;
  width: 35px;
  height: 35px;
  margin: -32px 0 0 -25px;
}

.marker-isStarred {
  background-color: var(--color-secondary);
}
