.profileHeader {
  margin-bottom: 44px;
  min-width: 736px;
  display: flex;
  flex-direction: row;
  padding-top: 2%;
}

.profileHeader--isMobile {
  min-width: 0px;
}

body {
  display: grid;
}

.followDiv {
  display: flex;
  height: 40px;
  width: 500px;
}

.followDiv--isMobile {
  display: flex;
  height: 40px;
}

.following {
  margin-left: 5%;
}

.horizontal-line {
  display: block;
  height: 1px;
  width: 75%;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0 auto;
  margin-bottom: 2%;
  padding: 0;
}

.profile-container-btn {
  position: relative;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: var(--font-primary);
  background-color: #ffffff;
  border: 1px solid var(--color-grey);
  color: var(--color-blackish);
  display: block;
  font-weight: 600;
  padding: 5px 9px;
  text-align: center;
  text-transform: inherit;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 18px;
  width: 100px;
}
