.MapToggle {
    position: fixed;
    left: 50%;
    padding: 16px;
    transform: translate(-50%, 0);
    bottom:1.8vh;
    z-index:10000;
    background: var(--color-primary);
    border-radius: 16px;
    color: white;
    font-weight: bold;
    
}