.searchTripCardSubtitle {
  color: grey;
  text-decoration: none;
  text-align: left;
  cursor: pointer;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 400;
  display: block;
  flex-basis: 100%;
  width: 100%;
}
