.TripCard--title {
  color: black;
  font-family: var(--font-primary);
}

.TripCard--likes {
  cursor: pointer;
  color: grey;
  text-decoration: none;
  text-align: left;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 400;
  display: block;
  flex-basis: 100%;
  width: 100%;
}

.TripCard--creator {
  cursor: pointer;
  color: grey;
  text-decoration: none;
  text-align: left;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 400;
  display: block;
  flex-basis: 100%;
  width: 100%;
}
