.TripHeader {
  padding: 0 10px 10px 10px;
}

.TripHeader--tripTitle {
  color: #fff;
  position: absolute;
  top: 240px;
  margin-left: 15px;
  font-weight: 750;
  line-height: 24px;
  font-family: var(--font-secondary);
}

.TripHeader--subtitle {
  color: #fff;
  position: absolute;
  margin-left: 15px;
  top: 265px;
  font-weight: 600;
  font-size: 12px;
  font-family: var(--font-secondary);
}
