.SignUpToolTip {
  width: 100%;
  border-radius: 4px;
  color: var(--color-primary);
  font-weight: 600 !important;
  padding: 5px 9px;
  text-align: left;
  background-color: white;
  z-index: 8;
  float: right;
  border: 1px solid var(--color-primary);
  display: flex;
}

.SignUpToolTip--button {
  border-radius: 4px;
  box-sizing: border-box;
  font-family: var(--font-primary);
  background-color: var(--color-primary);
  border: 0px;
  color: white;
  display: block;
  font-weight: 500;
  padding: 5px 9px;
  text-align: center;
  text-transform: inherit;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 18px;
  width: 125px;
  position: absolute;
  bottom: 15px;
}

.SignUpToolTip--exit {
  cursor: pointer;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.SignUpToolTip--exit:hover {
  color: #3078e1;
  opacity: 1;
}
