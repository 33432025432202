.modalBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    min-height: 40vh;
    overflow-y: auto;
    padding: 0px;
}
.modal-header {
    justify-content: center;
  }
  .modal-header .close {
    position: absolute;
    right: 15px;
  }
.EditPlaceModal--notesDiv {
    border: 1px #80868b solid;
    border-radius: 4px;
    display: flex;
    margin: 16px 0px;
}
.EditPlaceModal--notesTextArea {
    overflow: hidden;
    text-overflow: ellipsis;
    align-self: center;
    border: none;
    border-radius: inherit;
    box-sizing: border-box;
    color: #3c4043;
    display: -webkit-box;
    font-size: 14px;
    height: 100%;
    max-height: 36px;
    outline: none;
    padding: 3px 12px;
    resize: none;
    white-space: pre-wrap;
    width: 100%;
    word-break: break-word;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.EditPlaceModal--deleteButton {
    background-color: #f8d7da;  /* light red */
    color: #721c24;  /* dark red */
    padding: 5px 10px;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
    font-size: 0.8em;
    transition: background-color 0.3s ease;
    border: 1px solid #f5c6cb;  /* slightly darker pink */
    display: inline-block;
    margin-top: 10px;
}

.EditPlaceModal--deleteButton:hover {
    background-color: #f5c6cb;  /* slightly darker pink */
}
.EditPlaceModal--DatePicker {
    padding-bottom: 8px;
}
