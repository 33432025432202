.ConfirmationPill--save--pill {
  width: 175px;
  border-radius: 4px;
  color: #15b097;
  font-weight: 600 !important;
  padding: 5px 9px;
  text-align: center;
  background-color: var(--color-green-secondary);
  z-index: 8;
  float: right;
  border: 1px solid #15b097;
}
