.edit-container {
  max-width: 900px;
  width: 80%;
  height: 900px;
  z-index: 2;
  background-color: white;
  margin: 0 auto;
  position: relative;
  top: 50px;
  border-radius: 4px;
  border: 1px solid rgba(var(--b6a, 219, 219, 219), 1);
}

.page-container {
  width: 100%;
  height: 1000px;
  background-color: #f2f4f5;
}

.username-container {
  display: flex;
}

.image-container {
  width: 100px;
  height: 100px;
}

.edit-header {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-left: 10%;
}

.edit-header--isMobile {
  align-items: center;
  margin-left: 10%;
}

.profile-photo-text {
  cursor: pointer;
  color: dodgerblue;
  font-weight: 600;
}

.profile-text {
  font-weight: 600;
}

.picture-save-button {
  position: absolute;
  top: 78%;
  right: 5%;
  display: inline;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: var(--font-primary);
  background-color: #ffffff;
  border: 1.5px solid var(--color-primary) !important;
  color: var(--color-primary);
  font-weight: 600 !important;
  padding: 5px 9px;
}

.EditProfileView--button {
  width: 200px;
  position: relative;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: var(--font-primary);
  background-color: #ffffff;
  border: 1.5px solid var(--color-primary) !important;
  color: var(--color-primary);
  font-weight: 600 !important;
  padding: 5px 9px;
  text-align: center;
  text-transform: inherit;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 18px;
  margin-top: 10px;
}

.EditProfileView--button:hover {
  background-color: rgba(101, 157, 246, 0.2);
}

.EditProfileView--save--pill--container {
  position: absolute;
  width: 100%;
  top: 68px;
  z-index: 8;
  padding-right: 36px;
}
