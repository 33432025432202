.ShareTripButton {
    position: relative;
    margin-top: 8px;
}
.ShareTripButton:hover {
    text-decoration: underline;
}

.ShareTripButton--shareText {
    font-size: 18px;
    position: absolute;
    top: 2px;
    margin-left: 3px;
    font-family: var(--font-primary);
    color: var(--color-blackish);
    padding-right: 8px;
}
.ShareTripButton--shareText:hover {
    text-decoration: underline;
}