.notificationsBell {
    fill: grey;
}
.notificationsBell:hover {
    fill: blue;
}
.notificationsBell:focus-within {
    fill: blue;
}
.notificationsBell--isOpen {
    fill: blue;
}

.notificationsDropdown {
  width: 300px;
  height: 500px;
  position: absolute;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 8px;
  margin-left: -200px;
  text-align: center;
  z-index: 1000;
  background-color: white;
}

.notificationsHeader{
    text-align: left;
    margin: 4px 8px;
}
