.ReviewItem--container {
  margin: 10px;
  align-items: center;
}

.ReviewItem--header {
  margin: 10px;
  align-items: center;
  display: flex;
  font-weight: bold;
}

.ReviewItem--subheader {
  margin: 10px;
  align-items: center;
  display: flex;
}

.ReviewItem--stars {
  margin-right: 10px;
  color: var(--color-primary);
}

.ReviewItem--date {
  color: grey;
}

.ReviewItem--author--img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.ReviewItem--line {
  display: block;
  height: 1px;
  width: 94%;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0 auto;
  margin-bottom: 2%;
  padding: 0;
}
