.LikesItemSVG {
    fill: var(--color-primary);
}
.LikesItemText {
    color: var(--color-grey);
    font-size: 14px;
}
.LikesItemText:hover {
    text-decoration: underline;
}
