.ProgressMeter--container {
  width: 150px;
  height: 8px;
  position: relative;
  padding-left: 15px;
}

.ProgressMeter--meter {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-color: #e8eaed;
  position: absolute;
}

.ProgressMeter--progress--line {
  /* width: 50%; */
  height: 100%;
  border-radius: 4px;
  background-color: #fbbc04;
  position: absolute;
}
